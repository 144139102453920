export function Logo() {
  return (
    <svg
      width="99"
      height="32"
      viewBox="0 0 99 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1597 0.444336H1.93735C1.05316 0.444336 0.335694 1.15982 0.333252 2.044V15.4972C0.335694 16.3814 1.05316 17.0968 1.93735 17.0968H7.69793V30.293C7.69793 31.1789 8.41611 31.8971 9.30203 31.8971H22.7641C23.1891 31.8971 23.5966 31.728 23.8968 31.427C24.1969 31.126 24.3649 30.718 24.3637 30.293V17.0968H30.1597C31.0439 17.0968 31.7614 16.3814 31.7638 15.4972V2.044C31.7614 1.15982 31.0439 0.444336 30.1597 0.444336ZM2.17221 2.30101H15.1069V15.2402H2.17221V2.30101ZM22.507 30.036H9.5679V17.0968H22.507V30.036ZM29.9027 15.2402H16.968V2.30101H29.9027V15.2402Z"
        fill="#1C1C1C"
      />
      <path
        d="M10.9503 10.9106C11.1253 11.0861 11.3628 11.1849 11.6106 11.1854C11.9856 11.1836 12.3229 10.9569 12.4662 10.6104C12.6095 10.2638 12.5307 9.86506 12.2664 9.599L9.29747 6.62566C9.12126 6.44923 8.88214 6.3501 8.63279 6.3501C8.38344 6.3501 8.14432 6.44923 7.96811 6.62566L4.9992 9.599C4.6321 9.9661 4.6321 10.5613 4.9992 10.9284C5.36629 11.2955 5.96147 11.2955 6.32856 10.9284L8.64165 8.61971L10.9503 10.9106Z"
        fill="#1C1C1C"
      />
      <path
        d="M21.1245 10.9106L23.4376 8.60198L25.7462 10.9106C25.9212 11.0861 26.1587 11.1849 26.4065 11.1854C26.7815 11.1836 27.1188 10.9569 27.2621 10.6104C27.4054 10.2638 27.3266 9.86506 27.0623 9.599L24.0934 6.62566C23.9172 6.44923 23.678 6.3501 23.4287 6.3501C23.1793 6.3501 22.9402 6.44923 22.764 6.62566L19.7951 9.599C19.428 9.9661 19.428 10.5613 19.7951 10.9284C20.1622 11.2955 20.7574 11.2955 21.1245 10.9284V10.9106Z"
        fill="#1C1C1C"
      />
      <path
        d="M13.7243 21.4262C13.3572 21.0591 12.762 21.0591 12.3949 21.4262C12.0279 21.7933 12.0279 22.3885 12.3949 22.7556L15.3683 25.7289C15.5439 25.9064 15.7833 26.0063 16.033 26.0063C16.2827 26.0063 16.522 25.9064 16.6977 25.7289L19.6666 22.7556C20.0337 22.3885 20.0337 21.7933 19.6666 21.4262C19.2995 21.0591 18.7043 21.0591 18.3372 21.4262L16.0241 23.7393L13.7243 21.4262Z"
        fill="#1C1C1C"
      />
      <path
        d="M40.1919 12.8516C39.3873 12.4214 38.7172 11.7774 38.2554 10.9905C37.3126 9.33234 37.3126 7.30023 38.2554 5.64205C38.7231 4.85672 39.3955 4.21338 40.2007 3.78094C41.7692 2.97954 43.6073 2.89394 45.2435 3.54609C45.7766 3.76866 46.2635 4.08876 46.6792 4.48994C46.7615 4.56854 46.8081 4.67742 46.8081 4.79126C46.8081 4.9051 46.7615 5.01398 46.6792 5.09258L46.0632 5.66864C45.8982 5.80797 45.6567 5.80797 45.4916 5.66864C44.8306 5.06488 43.9628 4.7381 43.0677 4.75581C42.4181 4.74011 41.7754 4.89276 41.2022 5.19893C40.6626 5.49079 40.2158 5.92838 39.9127 6.46183C39.2982 7.60923 39.2982 8.9879 39.9127 10.1353C40.2158 10.6687 40.6626 11.1063 41.2022 11.3982C41.7754 11.7044 42.4181 11.857 43.0677 11.8413C43.9641 11.8571 44.8322 11.527 45.4916 10.9196C45.6521 10.7683 45.9028 10.7683 46.0632 10.9196L46.6925 11.5533C46.8501 11.7233 46.8501 11.986 46.6925 12.1559C46.2743 12.5582 45.7843 12.8784 45.2479 13.0998C44.5266 13.4004 43.7517 13.5512 42.9702 13.5429C42.0004 13.5525 41.0442 13.3146 40.1919 12.8516Z"
        fill="#1C1C1C"
      />
      <path
        d="M53.0825 6.00531V6.82509C53.0836 6.93633 53.0402 7.04341 52.962 7.12249C52.8838 7.20157 52.7771 7.24606 52.6659 7.24605C52.0541 7.20953 51.4554 7.43324 51.0175 7.86199C50.5905 8.34967 50.3767 8.98802 50.4237 9.63448V12.9623C50.424 13.1986 50.2388 13.3936 50.0027 13.4054H49.0323C48.798 13.3914 48.6153 13.197 48.6157 12.9623V6.07621C48.6146 5.96498 48.6579 5.85789 48.7362 5.77881C48.8144 5.69973 48.9211 5.65524 49.0323 5.65525H49.9185C50.0298 5.65524 50.1364 5.69973 50.2147 5.77881C50.2929 5.85789 50.3363 5.96498 50.3351 6.07621V6.78521C50.8603 6.03185 51.721 5.5833 52.6393 5.58435C52.7551 5.57693 52.8687 5.61814 52.9528 5.69804C53.0369 5.77795 53.0839 5.88931 53.0825 6.00531Z"
        fill="#1C1C1C"
      />
      <path
        d="M61.5549 10.0958H55.8873C55.982 10.6302 56.2808 11.1069 56.7204 11.4251C57.2059 11.7702 57.7913 11.9461 58.3865 11.9258C59.0398 11.9438 59.6794 11.7374 60.1989 11.3409C60.3789 11.2221 60.6184 11.2501 60.7661 11.4074L61.2092 11.9126C61.3652 12.0712 61.3652 12.3255 61.2092 12.4842C60.9046 12.7662 60.5499 12.9886 60.1635 13.14C58.8488 13.6239 57.3942 13.5552 56.1311 12.9495C55.5071 12.6329 54.9872 12.1438 54.6333 11.5403C54.2766 10.9178 54.0944 10.2105 54.106 9.49311C54.0965 8.78325 54.2738 8.08337 54.62 7.46361C54.9524 6.86967 55.4454 6.38133 56.0424 6.05448C56.6726 5.71065 57.3807 5.53514 58.0985 5.5449C58.8026 5.53428 59.4974 5.70683 60.1147 6.04562C60.7007 6.37309 61.1817 6.86021 61.5017 7.45032C61.8442 8.09178 62.0166 8.81036 62.0024 9.53742C62.0024 9.5773 62.0024 9.62161 62.0024 9.67036C61.9928 9.90986 61.7945 10.0983 61.5549 10.0958ZM56.6008 7.53008C56.1972 7.87883 55.9382 8.36542 55.874 8.89489H60.2521C60.1935 8.36991 59.9425 7.88524 59.5475 7.53451C58.6857 6.84662 57.4626 6.84662 56.6008 7.53451V7.53008Z"
        fill="#1C1C1C"
      />
      <path
        d="M69.2253 6.38674C69.8161 6.93325 70.1115 7.7545 70.1115 8.85049V12.9582C70.1118 13.1946 69.9266 13.3895 69.6905 13.4013H68.8043C68.57 13.3873 68.3873 13.1929 68.3878 12.9582V12.4398C68.1559 12.7858 67.8256 13.0544 67.4395 13.2108C66.4718 13.58 65.4005 13.5705 64.4396 13.1842C64.0337 13.009 63.6852 12.7235 63.4337 12.36C63.198 12.0058 63.0745 11.5889 63.0792 11.1636C63.0584 10.5153 63.3461 9.8956 63.8546 9.49302C64.3687 9.07648 65.184 8.86822 66.2918 8.86822H68.2858V8.74857C68.3158 8.28398 68.139 7.82988 67.8028 7.50783C67.3949 7.18556 66.8812 7.0275 66.3627 7.06471C65.9277 7.0642 65.4954 7.13301 65.0821 7.26855C64.8507 7.34399 64.628 7.4435 64.4174 7.56544C64.212 7.67564 63.9563 7.60785 63.8325 7.41035L63.5312 6.85645C63.4226 6.66258 63.4873 6.41757 63.6774 6.30254C64.0321 6.0902 64.4136 5.92627 64.8118 5.81511C65.3997 5.64756 66.0084 5.56402 66.6197 5.56696C67.5603 5.50414 68.4901 5.7967 69.2253 6.38674ZM67.5769 11.8504C67.9217 11.652 68.1853 11.3381 68.3213 10.9642V10.0779H66.4558C65.4144 10.0779 64.896 10.4236 64.896 11.106C64.8854 11.4152 65.0321 11.7088 65.2859 11.8859C65.6052 12.0977 65.9846 12.2003 66.3671 12.1783C66.7928 12.182 67.2113 12.0686 67.5769 11.8504Z"
        fill="#1C1C1C"
      />
      <path
        d="M77.1306 12.6436C77.2107 12.8399 77.1306 13.065 76.9445 13.1665C76.8028 13.2408 76.6545 13.3016 76.5014 13.3482C76.1888 13.4379 75.865 13.4826 75.5398 13.4811C74.8214 13.5267 74.1141 13.2862 73.5724 12.812C73.08 12.2922 72.8277 11.5899 72.8767 10.8756V7.1578H72.0259C71.7912 7.15822 71.5968 6.97555 71.5828 6.74126V6.13419C71.5946 5.89815 71.7896 5.71293 72.0259 5.71322H72.8767V4.38386C72.8885 4.14782 73.0835 3.9626 73.3198 3.96289H74.2902C74.4015 3.96289 74.5081 4.00738 74.5863 4.08645C74.6646 4.16553 74.708 4.27262 74.7068 4.38386V5.71322H76.3552C76.4672 5.71202 76.575 5.75599 76.6542 5.8352C76.7334 5.9144 76.7773 6.02218 76.7761 6.13419V6.74126C76.7761 6.85251 76.7317 6.95913 76.6526 7.03737C76.5735 7.11562 76.4664 7.15898 76.3552 7.1578H74.7068V10.8313C74.6854 11.1424 74.7837 11.4499 74.9815 11.6909C75.192 11.9005 75.4828 12.0087 75.7791 11.9878C76.0013 11.992 76.2222 11.9545 76.4305 11.877C76.6446 11.7959 76.8841 11.9027 76.9667 12.1163L77.1306 12.6436Z"
        fill="#1C1C1C"
      />
      <path
        d="M79.3372 5.65527H80.3077C80.4189 5.65527 80.5255 5.69976 80.6038 5.77884C80.682 5.85791 80.7254 5.965 80.7242 6.07624V12.9579C80.7246 13.1926 80.542 13.387 80.3077 13.401H79.3372C79.1012 13.3892 78.916 13.1942 78.9163 12.9579V6.07624C78.9274 5.84851 79.1095 5.6664 79.3372 5.65527Z"
        fill="#1C1C1C"
      />
      <path
        d="M90.2469 6.23585L87.2957 13.1175C87.2258 13.2689 87.0768 13.3682 86.9102 13.3745H85.5809C85.4142 13.3682 85.2653 13.2689 85.1953 13.1175L82.2619 6.23585C82.2046 6.10628 82.2171 5.95649 82.2951 5.83821C82.373 5.71993 82.5058 5.64938 82.6474 5.6509H83.6134C83.78 5.65724 83.929 5.75656 83.9989 5.90794L86.2899 11.3761L88.6561 5.90351C88.7215 5.75089 88.8712 5.65166 89.0372 5.6509H89.8614C90.0031 5.64938 90.1358 5.71993 90.2138 5.83821C90.2917 5.95649 90.3042 6.10628 90.2469 6.23585Z"
        fill="#1C1C1C"
      />
      <path
        d="M98.4183 10.0953H92.7774C92.8705 10.6292 93.1677 11.1059 93.606 11.4247C94.0931 11.7698 94.6799 11.9457 95.2766 11.9254C95.9285 11.9441 96.567 11.7376 97.0845 11.3405C97.2645 11.2216 97.504 11.2497 97.6517 11.407L98.0948 11.9121C98.2401 12.0784 98.2304 12.3292 98.0726 12.4837C97.7673 12.7647 97.4128 12.9871 97.0269 13.1396C95.7122 13.6234 94.2577 13.5547 92.9945 12.949C92.3697 12.6337 91.8495 12.1443 91.4967 11.5399C91.1386 10.9179 90.9563 10.2103 90.9694 9.49267C90.957 8.78246 91.1344 8.08182 91.4834 7.46317C91.8159 6.86923 92.3088 6.38089 92.9059 6.05405C93.5344 5.7102 94.2411 5.53466 94.9575 5.54446C95.6631 5.53328 96.3594 5.70584 96.9781 6.04518C97.5641 6.37265 98.0451 6.85977 98.3651 7.44988C98.7063 8.09168 98.8772 8.81028 98.8614 9.53698V9.66992C98.8519 9.9077 98.6563 10.0955 98.4183 10.0953ZM93.4686 7.52964C93.0655 7.87927 92.8052 8.36512 92.7375 8.89445H97.1199C96.9996 7.79087 96.0445 6.96943 94.9353 7.01562C94.3996 6.99927 93.8769 7.18245 93.4686 7.52964Z"
        fill="#1C1C1C"
      />
      <path
        d="M41.5746 20.5531H38.6412C38.5254 20.5605 38.4118 20.5193 38.3277 20.4394C38.2436 20.3595 38.1966 20.2481 38.198 20.1321V19.3788C38.1978 19.2636 38.2453 19.1534 38.3293 19.0745C38.4133 18.9955 38.5262 18.9549 38.6412 18.9623H46.3914C46.5018 18.9623 46.6078 19.0062 46.6859 19.0843C46.764 19.1624 46.8079 19.2683 46.8079 19.3788V20.1321C46.8091 20.2434 46.7657 20.3504 46.6875 20.4295C46.6092 20.5086 46.5026 20.5531 46.3914 20.5531H43.4535V28.6666C43.4535 28.8967 43.267 29.0832 43.0369 29.0832H41.9956C41.8844 29.0844 41.7773 29.041 41.6982 28.9627C41.6191 28.8845 41.5746 28.7779 41.5746 28.6666V20.5531Z"
        fill="#1C1C1C"
      />
      <path
        d="M65.1442 22.1219C65.7114 22.6832 65.995 23.5296 65.995 24.661V28.6491C65.995 28.8791 65.8085 29.0656 65.5784 29.0656H64.608C64.3819 29.0547 64.2004 28.8751 64.187 28.6491V24.8914C64.2297 24.3463 64.0722 23.8043 63.7439 23.3671C63.4157 23.0097 62.943 22.8206 62.4589 22.8531C61.9075 22.8297 61.3734 23.0482 60.9966 23.4513C60.6032 23.9329 60.4069 24.5456 60.4471 25.1661V28.6668C60.4471 28.8969 60.2606 29.0833 60.0306 29.0833H59.0557C58.8296 29.0724 58.648 28.8928 58.6347 28.6668V24.8914C58.6774 24.3463 58.5199 23.8043 58.1916 23.3671C57.8634 23.0097 57.3907 22.8206 56.9066 22.8531C56.3527 22.8247 55.8144 23.0419 55.4354 23.4468C55.0449 23.9313 54.8518 24.5454 54.8948 25.1661V28.6668C54.8948 28.8969 54.7083 29.0833 54.4783 29.0833H53.5078C53.2731 29.0838 53.0788 28.9011 53.0647 28.6668V21.7807C53.0788 21.5464 53.2731 21.3637 53.5078 21.3642H54.3941C54.6202 21.3751 54.8017 21.5547 54.815 21.7807V22.3435C55.1054 21.9949 55.4766 21.7226 55.8962 21.5503C56.3561 21.363 56.8488 21.2696 57.3452 21.2755C57.8757 21.2648 58.4017 21.3755 58.8829 21.599C59.3225 21.8105 59.6918 22.1444 59.9464 22.5606C60.2728 22.151 60.6941 21.827 61.1738 21.6167C61.6985 21.3853 62.2666 21.269 62.8399 21.2755C63.6908 21.235 64.5218 21.5403 65.1442 22.1219Z"
        fill="#1C1C1C"
      />
      <path
        d="M80.7242 3.20035C80.7242 2.70111 80.3195 2.29639 79.8202 2.29639C79.321 2.29639 78.9163 2.70111 78.9163 3.20035C78.9163 3.6996 79.321 4.10432 79.8202 4.10432C80.3195 4.10432 80.7242 3.6996 80.7242 3.20035Z"
        fill="#1C1C1C"
      />
      <path
        d="M49.0324 21.3862H50.0028C50.2305 21.3974 50.4126 21.5795 50.4238 21.8072V28.6889C50.4241 28.9252 50.2388 29.1202 50.0028 29.132H49.0324C48.9171 29.1322 48.807 29.0847 48.728 29.0007C48.6491 28.9168 48.6085 28.8039 48.6158 28.6889V21.8072C48.6268 21.5811 48.8064 21.3995 49.0324 21.3862Z"
        fill="#1C1C1C"
      />
      <path
        d="M50.4237 18.9313C50.4237 18.4321 50.0189 18.0273 49.5197 18.0273C49.0204 18.0273 48.6157 18.4321 48.6157 18.9313C48.6157 19.4306 49.0204 19.8353 49.5197 19.8353C50.0189 19.8353 50.4237 19.4306 50.4237 18.9313Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}

export default Logo;
