import React from "react";
import Link from "next/link";
import {
  Navbar as MTNavbar,
  Collapse,
  IconButton,
  Chip,
  Typography,
  List,
  ListItem,
  Tooltip,
  Button,
  Input,
} from "@material-tailwind/react";
import { Logo } from "widgets/logo";
import { formatNumber } from "@utils";
import router, { useRouter } from "next/router";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function NavItem({
  children,
  href,
}: {
  children: React.ReactNode;
  href?: string;
}) {
  const LinkEl = href ? Link : "span";

  return (
    <LinkEl passHref href={href ? href : ""}>
      <ListItem className="py-2.5 px-4 text-sm text-blue-gray-800 hover:text-primary">
        {children}
      </ListItem>
    </LinkEl>
  );
}


export function SoftwareNavbar({partner = "tekpon"}) {
  const [open, setOpen] = React.useState(false);
  let partnerLink = "/software";

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 960 && setOpen(false);
    });
  }, []);


  if (partner === "waydev") {
    partnerLink = "/software-engineering-intelligence";
  } else if (partner === "tekpon") {
    partnerLink = "/software";
  }

  const menuOpenIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 stroke-primary text-primary"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  );

  const menuCloseIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 stroke-primary text-primary"
      viewBox="0 0 24 24"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );

  const navbarMenu = (
    <div className="flex w-full flex-col justify-end lg:!ml-auto lg:flex-row">
      <List className="px-0 lg:!flex-row">
        <NavItem href="/software-engineering-intelligence">SEI</NavItem>
        <NavItem href="/software">Companies</NavItem>
        <NavItem href="/software/news">News</NavItem>
        <NavItem href="https://creative-tim.com/">Marketplace</NavItem>
        <NavItem href="https://www.creative-tim.com/blog">Blog</NavItem>
      </List>
    </div>
  );

  return (
    <div className="sticky !top-4 z-[999] flex w-full items-center px-4">
      <MTNavbar
        className="mx-auto w-full bg-white py-1.5 !pr-3 !pl-2 lg:!py-0.5 lg:!px-4"
        shadow={false}
      >
        <div
          className={`flex w-full items-center !justify-between`}
        >
          <div className="flex">
            <Link
              href={partnerLink}
              className="mr-4 flex items-center gap-2 text-inherit ml-2 lg:ml-0"
            >
              <Logo /> 
            </Link>
          </div>
          
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-primary hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpen(!open)}
          >
            {open ? menuCloseIcon : menuOpenIcon}
          </IconButton>
          <div className="hidden flex-grow basis-full items-center lg:flex lg-max:max-h-0">
            {navbarMenu}
          </div>
        </div>

        <Collapse open={open}>
          <div className="overflow-hidden pb-1 lg:overflow-visible">
            {navbarMenu}
          </div>
        </Collapse>
      </MTNavbar>
    </div>
  );
}

export default SoftwareNavbar;
