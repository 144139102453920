import Link from "next/link";

const YEAR = new Date().getFullYear();

const COMPANY = [
  {
    name: "About Us",
    link: "https://www.creative-tim.com/presentation?ref=software-page",
  },
  {
    name: "Freebies",
    link: "https://www.creative-tim.com/templates/free?ref=software-page",
  },
  {
    name: "Premium",
    link: "https://www.creative-tim.com/templates/premium?ref=software-page",
  },
  {
    name: "Blog",
    link: "https://www.creative-tim.com/blog?ref=software-page",
  },
  {
    name: "Affiliate Program",
    link: "https://www.creative-tim.com/affiliates/new?ref=software-page",
  },
  {
    name: "Get Coupon",
    link: "https://www.creative-tim.com/coupon?ref=software-page",
  },
];

const HELP = [
  {
    name: "Knowledge Center",
    link: "https://www.creative-tim.com/knowledge-center?ref=software-page",
  },
  {
    name: "Contact Us",
    link: "https://www.creative-tim.com/contact-us?ref=software-page",
  },
  {
    name: "Premium Support",
    link: "https://www.creative-tim.com/support-terms?ref=software-page",
  },
  {
    name: "Sponsorships",
    link: "https://www.creative-tim.com/sponsorships?ref=software-page",
  },
  {
    name: "Custom Development",
    link: "https://www.creative-tim.com/services/updivision/?ref=software-page",
  },
  {
    name: "Mints - Expert Design",
    link: "https://mints.team/?ref=software-page",
  },
];

const LEGAL = [
  {
    name: "Terms & Conditions",
    link: "https://www.creative-tim.com/knowledge-center/terms-of-service/?ref=software-page",
  },
  {
    name: "Privacy Policy",
    link: "https://creative-tim.com/knowledge-center/privacy-policy/?ref=software-page",
  },
  {
    name: "Licenses",
    link: "https://www.creative-tim.com/license?ref=software-page",
  },
];

const RESOURCES = [
  {
    name: "GPTs Collection",
    link: "https://www.creative-tim.com/gpts?ref=software-page",
  },
  {
    name: "Bootstrap Cheat Sheet",
    link: "https://www.creative-tim.com/cheatsheet/bootstrap4?ref=software-page",
  },
  {
    name: "ChatGpt Prompts",
    link: "https://www.creative-tim.com/cheatsheet/chatgpt-prompts/?ref=software-page",
  },
  {
    name: "AI Code Mentor",
    link: "https://code-mentor.ai/?ref=software-page",
  },
  {
    name: "Third-Party Tools",
    link: "https://www.creative-tim.com/tools?ref=software-page",
  },
  {
    name: "Illustrations",
    link: "https://iradesign.io/?ref=software-page",
  },
  {
    name: "Bits",
    link: "https://www.creative-tim.com/bits?ref=software-page",
  },
  {
    name: "Shapehost (50% discount)",
    link: "https://www.creative-tim.com/hosting/shapehost?ref=footer?ref=software-page",
  },
  {
    name: "Bluehost",
    link: "https://www.bluehost.com/?utm_medium=affiliate&irpid=105&channelid=P99C46097236S653N0B3A151D855E0000V100&utm_source=IR?ref=software-page",
  },
  {
    name: "Digital Ocean",
    link: "https://www.digitalocean.com/?refcode=276af09d9975&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=CopyPaste?ref=software-page",
  },
  {
    name: "Made with Creative Tim",
    link: "https://www.creative-tim.com/gallery/material-kit-pro?ref=software-page",
  }
];


const SOCIAL_MEDIA = [
  {
    icon: "fab fa-github",
    link: "https://github.com/creativetimofficial?ref=software-page",
  },
  {
    icon: "fab fa-dribbble",
    link: "https://dribbble.com/creativetim?ref=software-page",
  },
  {
    icon: "fab fa-facebook-square",
    link: "https://www.facebook.com/CreativeTim?ref=software-page",
  },
  {
    icon: "fab fa-twitter",
    link: "https://twitter.com/CreativeTim?ref=software-page",
  },
  {
    icon: "fab fa-instagram",
    link: "https://www.instagram.com/creativetimofficial/?ref=software-page",
  },
  {
    icon: "fab fa-youtube",
    link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w?ref=software-page",
  },
  {
    icon: "fab fa-pinterest",
    link: "https://ro.pinterest.com/thecreativetim/?ref=software-page",
  }
];


export function Footer() {
  return (
    <footer className="relative z-40 bg-gray-900 px-8 pt-24 pb-6">
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <div className="mx-auto w-full md:px-24">
            <div className="items-top mb-6 flex flex-wrap">
              <div className="w-6/12 pt-6 md:ml-auto md:px-4 md:pt-0 xl:w-3/12">
                <span className="text-md mb-4 block font-medium text-white">
                  Company
                </span>
                <ul className="list-unstyled">
                  {COMPANY.map(({ name, link }, key) => (
                    <li key={key}>
                      <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className="block pb-2 text-sm font-normal leading-relaxed text-white/60 transition-colors hover:text-white"
                      >
                        {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="ml-auto w-6/12 pt-6 md:px-4 md:pt-0 xl:w-3/12">
                <span className="text-md mb-4 block font-medium text-white">
                  Help and Support
                </span>
                <ul className="list-unstyled">
                  {HELP.map(({ name, link }, key) => (
                    <li key={key}>
                      <Link
                        href={link}
                        rel="noreferrer"
                        target={name === "Pricing" ? "_self" : "_blank"}
                        className="block pb-2 text-sm font-normal leading-relaxed text-white/60 transition-colors hover:text-white"
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="ml-auto w-6/12 pt-6 md:px-4 md:pt-0 xl:w-3/12">
                <span className="text-md mb-4 block font-medium text-white">
                  Legal
                </span>
                <ul className="list-unstyled">
                  {LEGAL.map(({ name, link }, key) => (
                    <li key={key}>
                      <Link
                        href={link}
                        className="block pb-2 text-sm font-normal leading-relaxed text-white/60 transition-colors hover:text-white"
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="ml-auto w-6/12 pt-6 md:px-4 md:pt-0 xl:w-3/12">
                <span className="text-md mb-4 block font-medium text-white">
                  Resources
                </span>
                <ul className="list-unstyled">
                  {RESOURCES.map(({ name, link }, key) => (
                    <li key={key}>
                      <Link
                        href={link}
                        rel="noreferrer"
                        target={name === "Documentation" ? "_self" : "_blank"}
                        className="block pb-2 text-sm font-normal leading-relaxed text-white/60 transition-colors hover:text-white"
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blue-gray-50" />
        <div className="w-full md:w-4/12 mx-auto text-center">
            <a href="/software">
              <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/software/logo-creative-tim.png" alt="logo" className="w-24 mx-auto" />
            </a>
            <div className="mt-6">
              {SOCIAL_MEDIA.map(({ icon, link }, key) => (
                <a key={key} href={link} target="_blank" rel="noreferrer">
                  <i
                    className={`${icon} font-xl align-center mr-2 inline-block items-center justify-center rounded-full border-[1.5px] border-blue-gray-50 p-3 text-center text-white outline-none focus:outline-none`}
                  />
                </a>
              ))}
            </div>
          </div>
        <div className="flex flex-wrap items-center justify-center gap-2">
          <div className="text-center">
            <div className="text-md mt-2 py-1 font-normal text-gray-600">
              &copy; {YEAR}{" "}
              <a
                href="https://www.creative-tim.com"
                className="text-inherit transition-all"
              >
                Creative Tim, all rights reserved. Made with ❤️ for a better web.
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
